import React, { Component } from "react";
import { Provider } from "react-redux";
import { StoreProvider } from "./redux/hooks";

import store from "./redux/store/store";

import { BoardUI } from "./ui/board";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <StoreProvider value={store}>
          <BoardUI />
        </StoreProvider>
      </Provider>
    );
  }
}

export default App;
