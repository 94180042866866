import {
  StoreState,
  PerformancePriceEntity,
  PerformanceEntity,
} from "../store/types";
import { Action, ReceievePerformancesAction } from "../actions/types";

import _ from "lodash";
import { DateTime } from "luxon";

const initialState: StoreState = {
  session: {
    initialised: false,
    displayDate: DateTime.utc().toSQLDate(),
  },
  entities: {
    performances: {},
  },
  newEntities: null,
  boardUI: {
    perfReceivedAt: null,
    initiated: false,
    renderSpacers: false,
    page: {
      pageCount: 0,
      pageNumber: 0,
    },
    now: DateTime.utc().toMillis(),
  },
};

function reducer(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case "INIT_SUCCESS":
      return {
        ...state,
        session: {
          ...state.session,
          displayDate: action.displayDate,
          initialised: true,
        },
      };

    case "RECEIVE_PERFORMANCES": {
      const response = (action as ReceievePerformancesAction).response;

      // normalise
      const performances = _.reduce(
        response,
        (acc: any, piNfsPerformance: PerformanceEntity) => {
          const performance: PerformanceEntity = {
            ...piNfsPerformance,
            prices: _.map(
              piNfsPerformance.prices,
              (piNfsPrice: PerformancePriceEntity): PerformancePriceEntity => {
                return {
                  ...piNfsPrice,
                };
              }
            ),
          };

          const hasAvailablePrice = _.some(
            performance.prices,
            (price: { onsale: any; }) => price.onsale
          );

          if (hasAvailablePrice) {
            acc[piNfsPerformance.hash] = performance;
          }
          return acc;
        },
        {}
      ) as { [id: string]: PerformanceEntity };

      return {
        ...state,
        boardUI: {
          ...state.boardUI,
          perfReceivedAt: DateTime.utc().toMillis(),
        },
        newEntities: {
          ...state.entities,
          performances: _.merge({}, performances),
        },
      };
    }

    case "GET_AVAILABLE_SHOWS":
      return {
        ...state,
        boardUI: {
          ...state.boardUI,
        },
      };

    case "BOARD_UI_INIT":
      return {
        ...state,
        boardUI: {
          ...state.boardUI,
          page: action.page,
          initiated: true,
          renderSpacers: true,
        },
      };

    case "UPDATE_BOARD_UI":
      return {
        ...state,
        boardUI: {
          ...state.boardUI,
          page: action.page,
        },
      };

    case "SET_ENTITIES_FROM_NEW_ENTITIES":
      if (state.newEntities === null) {
        return state;
      }

      return {
        ...state,
        boardUI: {
          ...state.boardUI,
          now: DateTime.utc().toMillis(),
          renderSpacers: false,
        },
        entities: {
          ...state.newEntities,
        },
        newEntities: null,
      };

    default:
      return state;
  }
}

export default reducer;
