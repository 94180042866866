import { createSelector } from "reselect";
import { StoreState, PerformanceEntity } from "./store/types";
import _ from "lodash";
import { Show } from "../domain/board-ticketing";
import * as ticketing from "@soltshared/shared-domain/ticketing";
import { SQLDate } from "@soltshared/shared-domain/util";

import createCachedSelector from "re-reselect";
import { DateTime } from "luxon";

export function displayDate(state: StoreState) {
  return state.session.displayDate;
}

export function performances(state: StoreState) {
  return state.entities.performances;
}

export function haveMatinees(state: StoreState) {
  const perfs = performances(state);
  return !!_.find(_.values(perfs), (performance: PerformanceEntity) => {
    return ticketing.isMatineeTime(performance.starts);
  });
}

export function haveEvenings(state: StoreState) {
  const perfs = performances(state);
  return !!_.find(_.values(perfs), (performance: PerformanceEntity) => {
    return ticketing.isEveningTime(performance.starts);
  });
}

export const shows = createSelector(
  (state: StoreState) => performances(state),
  (performanceEntities) => {
    const byShowUrl = _.groupBy(
      _.values(performanceEntities) as PerformanceEntity[],
      "show_url"
    );

    return _.reduce(
      byShowUrl,
      (acc: Show[], performanceEntities) => {
        const p = performanceEntities[0];
        const show: Show = {
          show_name: p.show_name,
          show_url: p.show_url,
          venue_name: p.venue_name,
          starts: p.starts,
        };
        acc.push(show);
        return acc;
      },
      []
    );
  }
);

export const availablePerformanceDates = createSelector(
  (state: StoreState) => performances(state),
  (performances) => {
    const dates = _.map(
      _.values(performances) as PerformanceEntity[],
      (performance) => {
        return performance.starts.substr(0, 10);
      }
    );

    return _.chain(dates).uniq().sort().value();
  }
);

export const performancesForShowUrlAndDate = createCachedSelector(
  (state: StoreState) => performances(state),
  (state: StoreState, showName: string) => showName,
  (state: StoreState, showName: string, date: SQLDate) => date,
  (performances, show_name, date): PerformanceEntity[] => {
    const perfs = _.values(performances) as PerformanceEntity[];

    let ret = _.chain(perfs)
      .filter((performance) => {
        return performance.show_name === show_name;
      })
      .filter((performance) => {
        return ticketing.performanceDate(performance.starts) === date;
      })
      .value();
    return ret;
  }
)((state: StoreState, showUrl: string, date: string) => {
  return `${date}-${showUrl}`;
});

export const showsForDateTime = createCachedSelector(
  (state: StoreState, date: SQLDate) => performances(state),
  (state: StoreState, date: SQLDate) => date,
  (state: StoreState, date: SQLDate) => state.boardUI.now,
  (performances, date, dateTime) => {
    if (_.isEmpty(performances)) {
      return [] as Show[];
    }

    const byShowId = _.groupBy(_.values(performances), (perf) =>
      perf ? perf.id : null
    );
    return _.reduce(
      byShowId,
      (acc: { [showId: string]: Show }, _performances) => {
        if (_.isEmpty(_performances)) {
          return acc;
        }

        const performances: PerformanceEntity[] = _.chain(_performances)
          .compact()
          .filter((performance) => {
            return (
              ticketing.performanceDate(performance.starts) === date &&
              DateTime.fromSQL(performance.starts) >
                DateTime.fromMillis(dateTime)
            );
          })
          .value();

        // Only show 1 performance for starts time.
        const perStartsTime = _.flatMap(
          _.groupBy(performances, "starts"),
          (items) => {
            if (items.length > 1) {
              return (
                ((_.find(items, (item) => item.tktsInPersonOnly) ||
                  _.find(items, (item) => item.discount_available) ||
                  _.find(
                    items,
                    (item) => item.generatedFrom === "tkts-online"
                  ) ||
                  _.find(items, (item) => item.source === "tkts-booth") ||
                  _.find(
                    items,
                    (item) => item.source === "olt"
                  )) as PerformanceEntity) || undefined
              );
            }

            return items;
          }
        );

        //TODO: Fix this hack to make pick the right performance
        const p = perStartsTime[0];

        if (!p || !p.id) {
          return acc;
        }

        const daySeatPrice =  _.get(_.find(performances, {source: "day-seats"}), "price_from");
        const daySeatPriceString = daySeatPrice ? `£${daySeatPrice.toFixed(0)}` : ""

        const show = {
          show_name: p.shortTitle || p.show_name,
          searchTitle: p.searchTitle,
          show_url: p.show_url,
          venue_name: p.venue_name,
          showOfTheWeek: p.showOfTheWeek,
          provider: p.provider,
          url: p.url,
          image: p.image,
          id: p.id,
          hasDaySeats: _.some(performances, ["source", "day-seats"]),
          daySeatPriceString: daySeatPriceString
        };

        const perfs: Show["performancesToShow"] = _.map(
          perStartsTime,
          (perf) => ({
            price_from: perf.price_from,
            price_to: perf.price_to,
            prices: perf.prices,
            starts: perf.starts,
            accessPerformances: perf.accessPerformances,
            source: perf.source,
            ...(perf.part_number ? { part_number: perf.part_number } : {}),
          })
        );

        acc[p.id] = show;

        const existing = acc[p.id]["performancesToShow"];
        if (typeof existing !== "undefined") {
          acc[p.id]["performancesToShow"] = _.concat(existing, perfs);
        } else {
          acc[p.id]["performancesToShow"] = perfs;
        }

        return acc;
      },
      {}
    );
  }
)((state, date) => date);

export const performancesList = createSelector(performances, (performances) => {
  return _.values(performances);
});

export const performanceForPerfUrl = createCachedSelector(
  performancesList,
  (state: StoreState, perfUrl: string) => perfUrl,
  (performancesList, perfUrl) => {
    const p = _.find(performancesList, (performance) => {
      return performance!.perf_url === perfUrl;
    });
    return p ? p : null;
  }
)((state, perfUrl) => perfUrl);
