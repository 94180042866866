import _ from "lodash";
import { NfsPerformancesResponse } from "../gateway/api";
import { TKTSBoardPerformance } from "@soltshared/shared-domain/ticketing";

export interface Show {
  show_name: string;
  show_url: string;
  venue_name: string;
  starts?: string;
  url?: string;
  id?: string;
  image?: string;
  provider? : string;
  performancesToShow?: {
    provider?: string;
    price_from?: number;
    price_to?: number;
    prices: any[];
    starts: string;
    source?: string;
    accessPerformances?: any[];
    part_number?: number;
  }[];
  searchTitle?: string;
  showOfTheWeek?: boolean;
  hasDaySeats?: boolean;
  daySeatPriceString?: string;
}

export function checkForDuplicateShowNames(
  performances: NfsPerformancesResponse
) {
  return _.reduce(
    performances,
    (acc: any[], performance: TKTSBoardPerformance) => {
      const hasDuplicateShowName = _.some(acc, item => {
        return (
          item.show_name === performance.show_name &&
          item.venue_name !== performance.venue_name
        );
      });

      if (hasDuplicateShowName) {
        const newPerfomance = {
          ...performance,
          show_name: `${performance.show_name} (${performance.venue_name})`,
        };

        acc.push(newPerfomance);
      } else {
        acc.push(performance);
      }

      return acc;
    },
    []
  );
}
