import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import "./fonts.css";
import "./index.css";
import App from "./App";
import store from "./redux/store/store";
import * as actions from "./redux/actions/index";

ReactDOM.render(<App />, document.getElementById("root"));

store.dispatch(actions.init());
