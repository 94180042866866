import * as api from "../../gateway/api";
import { NfsPerformancesResponse } from "../../gateway/api";
import {
  ReceievePerformancesAction,
  UpdateBoardUI,
  SetEntitiesFromNewEntities,
  BoardUIInit,
  FetchShowsForBoard,
  GetAvailableShows,
} from "./types";
import { StoreState } from "../store/types";
import { checkForDuplicateShowNames } from "../../domain/board-ticketing";
import { SQLDate } from "@soltshared/shared-domain/util";
import { DateTime } from "luxon";

const params = new URLSearchParams(window.location.search);

export function receivePerformances(
  response: NfsPerformancesResponse
): ReceievePerformancesAction {
  return {
    type: "RECEIVE_PERFORMANCES",
    response,
  };
}

export function setEntitiesFromNewEntities(): SetEntitiesFromNewEntities {
  return {
    type: "SET_ENTITIES_FROM_NEW_ENTITIES",
  };
}

export function boardUIInit(
  page: any,
  initiated: boolean
): BoardUIInit {
  return {
    type: "BOARD_UI_INIT",
    page,
    initiated,
  };
}
export function updateBoardUI(page: any): UpdateBoardUI {
  return {
    type: "UPDATE_BOARD_UI",
    page
  };
}

export function fetchShowsForBoard(): FetchShowsForBoard {
  return {
    type: "FETCH_SHOWS_FOR_BOARD",
  };
}

export function getAvailableShows(): GetAvailableShows {
  return {
    type: "GET_AVAILABLE_SHOWS",
  };
}

export function init(): any {
  return async (dispatch: any, getState: () => StoreState) => {
    let today = DateTime.utc().toSQLDate();

    const displayDate = params.get("date")
      ? (params.get("date") as SQLDate)
      : today;

    dispatch({
      type: "INIT_SUCCESS",
      adminMode: false,
      displayDate,
    });

    const performances = await api.fetchPerformances();

    const _performances = checkForDuplicateShowNames(performances);

    dispatch(receivePerformances(_performances));
    dispatch(getAvailableShows());
    dispatch(setEntitiesFromNewEntities());

    const movePage = (direction: string) => {
      const state = getState();
      const pageNumber = state.boardUI.page.pageNumber;
      const pageCount = state.boardUI.page.pageCount;
      let page;

      if (direction.includes("Down") && pageNumber < pageCount ) {
        page = {
          ...state.boardUI.page,
          pageNumber: pageNumber + 1,
        };
        dispatch(updateBoardUI(page));
      } else if (direction.includes("Up") && pageNumber > 0) {
        page = {
          ...state.boardUI.page,
          pageNumber: pageNumber - 1,
        };
        dispatch(updateBoardUI(page));
      } else if (direction.includes("Up") && pageNumber === 0) {
        // Nothing
      } else if (direction.includes("Down") && pageNumber === pageCount - 1) {
        // Nothing
      } else if (pageNumber === pageCount - 1) {
        dispatch(setEntitiesFromNewEntities());

        page = {
          ...state.boardUI.page,
          pageNumber: 0,
        };
        dispatch(updateBoardUI(page));
      } else {
        page = {
          ...state.boardUI.page,
          pageNumber: pageNumber + 1,
        };

        dispatch(updateBoardUI(page));
      }
    }

    document.addEventListener("keydown", event => {
      const key = event.key;
      if (key.includes("Up") || key.includes("Down")) {
        movePage(key)
      }
    })
    
    //increment board page every 10 seconds
    setInterval(function() {
      movePage("auto")
    }, 10 * 1000);

    //Fetch new performances and store them in newEntities every minute
    setTimeout(function() {
      setInterval(async function() {
        const performances = await api.fetchPerformances();

        const _performances = checkForDuplicateShowNames(performances);
        dispatch(receivePerformances(_performances));
        dispatch(getAvailableShows());
      }, 60 * 1000);
    }, 5000);

    setInterval(() => {
      if (today !== DateTime.utc().toSQLDate()) {
        window.location.reload();
      }
    }, 1000 * 60 * 60);
  };
}
