import { TKTSBoardPerformance } from "@soltshared/shared-domain/ticketing";

function getPrefix() {
  if (/.*localhost.*/.test(window.location.href) || /.*ddev\.site.*/.test(window.location.href)) {
    return "local";
  } else if (
    /.*development-tktsboard\.positive\.officiallondontheatre\.com.*/.test(
      window.location.href
    )
  ) {
    return "dev";
  } else if (
    /.*staging-tktsboard\.positive\.officiallondontheatre\.com.*/.test(
      window.location.href
    )
  ) {
    return "stge";
  } else {
    return "prod";
  }
}

function getBaseApiUrl() {
  if (/.*localhost.*/.test(window.location.href) || /.*ddev\.site.*/.test(window.location.href)) {
    return "http://localhost:8080";
  } else if (
    /.*development-tktsboard\.positive\.officiallondontheatre\.com.*/.test(
      window.location.href
    )
  ) {
    return "https://development-olt-api.positive.officiallondontheatre.com";
  } else if (
    /.*staging-tktsboard\.positive\.officiallondontheatre\.com.*/.test(
      window.location.href
    )
  ) {
    return "https://staging-olt-api.positive.officiallondontheatre.com";
  } else {
    return "https://api.officiallondontheatre.com";
  }
}

const prefix = getPrefix();
const baseApiUrl = getBaseApiUrl();

const NFS_PERFORMANCES_URL = `${baseApiUrl}/static/${prefix}/tkts-booth-performances.json`;

function handleErrors(response: Response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

export type NfsPerformancesResponse = TKTSBoardPerformance[];

export async function fetchPerformances(): Promise<NfsPerformancesResponse> {
  const res = await fetch(NFS_PERFORMANCES_URL).then(handleErrors);
  return res.json();
}
