import React, { useContext } from "react";
import { Store } from "redux";
import { StoreState } from "./store/types";

const StoreProviderContext = React.createContext({});

export function useDispatch() {
  const store = useContext(StoreProviderContext) as Store<StoreState>;
  if (!store) {
    throw new Error("no store!");
  }

  return store.dispatch;
}

export const StoreProvider = StoreProviderContext.Provider;
